import { useParams } from "react-router-dom";
import { ApiService } from '../services/ApiService';

const certblockAPI = new ApiService();

const  SAPdocuRedirectComponent = async () => {

    let url = undefined;
    let { orderNumber, orderPosition, deliveryNumber, documentProfileId } = useParams(); 

    let response = await certblockAPI.callApi(
        'documentProfile',
        [orderNumber, deliveryNumber, orderPosition, documentProfileId],
        'get'
    );

    // console.log(response);
    url = response.data.downloadUrl;
    // sample: localhost:3000/document/3204228668/00010/20241115/000370
    // console.log(`url: ${url}`);
    if (url){
      window.location.href = url;
    }

    return null

};

export default SAPdocuRedirectComponent;