import React from 'react';
import ReactLoading from 'react-loading';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const SavingComponent = ({ hidden }) => {
    return <Modal animation={false} show={hidden}>
        <Modal.Body>
            <ReactLoading type="cubes" color="#000000" />
            <FormattedMessage id="commonMessages.saving" />
        </Modal.Body>
    </Modal>
};

export default SavingComponent;
